'use client'

import { useEffect, useState, useRef } from 'react'

import useRegion from '../../hooks/useRegion'
import analytics from '../../public/scripts/comscore'
import { video } from '../../styles/components/video/video'
import durationMap from './live-duration'
import { track } from './track'

import timezone from 'moment-timezone'
import Head from 'next/head'
import Script from 'next/script'
import { v4 as uuidv4 } from 'uuid'
import videojs from 'video.js'
import videojsContribAds from 'videojs-contrib-ads'
import 'videojs-ima'
import 'videojs-ima/dist/videojs.ima.css'
import 'videojs-overlay'

videojs.registerPlugin('ads', videojsContribAds)

const setUUIDHandler = uuid => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('uuid', uuid)
  }
  return uuid
}

const getVideoCaptionPreference = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('videoCaptions')
  }
}

export const adTag = (region, live = false, date, title = '', tags, slug, contentfulCategories) => {
  const pageDetails = () => {
    const video = 'y'
    const category = tags && tags.find(e => e.name === 'categories')
    const categoryTags = contentfulCategories ? contentfulCategories.join(';') || '' : category?.value.join(';') || ''
    const values = { slug, region: region.shortName, video, category: categoryTags, live }
    const details = Object.keys(values).reduce(
      (params, item) => params + (values[item] ? `&${item}=${values[item]}` : ''),
      ''
    )

    return details.length > 0 ? `cust_params=${encodeURIComponent(details)}` : null
  }

  const nowUnixTS = +date
  const uuid = (typeof window !== 'undefined' && localStorage.getItem('uuid')) || setUUIDHandler(uuidv4())

  return [
    'https://7dc33.v.fwmrm.net/ad/g/1',
    [
      'nw=515123',
      'resp=vmap1+vast4',
      'prof=515123:altice_n12_vast_mp4',
      `csid=ss_altice_digital_news12_${live ? 'live-video' : 'n12.com'}_${encodeURIComponent(
        region.shortName
      )}_on-domain_${live ? 'live' : 'on-demand'}`,
      'caid=n12_generic_asset', // TODO: Replace with video asset id if available
      `flag=${encodeURIComponent('+exvt+aeti+dtrd+qtcb+emcr+play+slcb')}`,
      `mode=${live ? 'live' : 'on-demand'}`,
      `vdty=${live ? 'variable' : 'exact'}`,
      `vrdu=${live ? '60' : ''}`,
      'metr=7',
      `pvrn=${encodeURIComponent(nowUnixTS)}`,
      `vprn=${encodeURIComponent(nowUnixTS)};_fw_vcid2=515123:${uuid}`,
      '_fw_content_genre=news',
      '_fw_content_language=english',
      `_fw_content_title=${encodeURIComponent(title)}`,
      `${pageDetails(tags, live, slug)};slid=preroll_${encodeURIComponent(nowUnixTS)}`,
      'slau=preroll',
      'ptgt=a',
      'maxa=1',
      `maxd=30`,
      `mind=0`,
      'Tpos=0'
    ].join('&')
  ].join('?')
}

const VideoPlayer = ({
  overlayText,
  type,
  src,
  poster,
  tags,
  slug,
  withAds = true,
  muted = true,
  isLive = false,
  autoplay = true,
  date = new Date(),
  duration = 0,
  title = '',
  contentfulCategories,
  className = ''
}) => {
  const region = useRegion()
  const [showControls, setShowControls] = useState(true)
  const [initialized, setInitialized] = useState(false)
  const [player, setPlayer] = useState(null)
  const timeOut = useRef(null)
  const videoNode = useRef(null)
  let quartiles = []
  let cleaningUp = false
  let trackedPlay = false
  let timer = null
  let startOfVideo = true

  useEffect(() => {
    if (!region.isReadyOnClientSide) {
      return
    }

    if (videoNode && !initialized && !player) setPlayer(setUpPlayer(withAds))

    if (player) {
      analytics.PlatformApi.setPlatformAPI(analytics.PlatformApi.PlatformApis.WebBrowser)

      let myPublisherConfig = new analytics.configuration.PublisherConfiguration({
        publisherId: 34363161
      })

      analytics.configuration.setApplicationName('News12')
      analytics.configuration.addClient(myPublisherConfig)
      analytics.configuration.setUsagePropertiesAutoUpdateMode(
        analytics.configuration.UsagePropertiesAutoUpdateMode.FOREGROUND_AND_BACKGROUND
      )
      analytics.configuration.enableImplementationValidationMode()
      analytics.start()

      let sa = new analytics.StreamingAnalytics()
      let cm = new analytics.StreamingAnalytics.ContentMetadata()

      cm.setUniqueId(src)
      cm.setProgramTitle(isLive ? `${region.shortName}-live` : overlayText)
      player.src({ src, type })
      player.on('adend', () => player.play())
      player.on('playing', () => playing(sa, cm))
      player.on('pause', () => pause(sa))
      player.on('ended', () => ended(sa))
      player.on('timeupdate', timeupdate)
    }

    return () => {
      cleaningUp = true
      if (isLive && initialized) {
        trackMinutesWatched()
        window.onunload = null
      }
    }
  }, [videoNode, initialized, player, src, type, cleaningUp, region.isReadyOnClientSide])

  const updateMetadata = (sa, cm) => {
    cm.setLength(Math.round(player.duration() * 1000) || 0)
    cm.setGenreName('News')
    cm.setPublisherName('News12')
    cm.setDictionaryClassificationC4('News12')
    cm.setMediaType(getMediaType(player, isLive))
    sa.setMetadata(cm)
  }

  const trackMinutesWatched = () => {
    const minutesWatched = Math.round(duration / 60)
    track(`Total_Duration`, videoAttrs({ total: minutesWatched }))
  }

  const getMediaType = (player, isLive) => {
    const videoLength = player.duration()
    const tenMinutes = 600

    if (isLive) {
      return analytics.StreamingAnalytics.ContentMetadata.ContentType.LIVE
    }

    if (videoLength >= tenMinutes) {
      return analytics.StreamingAnalytics.ContentMetadata.ContentType.LONG_FORM_ON_DEMAND
    }

    return analytics.StreamingAnalytics.ContentMetadata.ContentType.SHORT_FORM_ON_DEMAND
  }

  const videoAttrs = (extras = {}) =>
    Object.assign(
      {
        category: isLive ? 'LIVE' : 'VOD',
        label: overlayText || title || 'generic video'
      },
      extras
    )

  const videoRemains = () => ({
    'Time Elapsed': player.currentTime(),
    'Time Remaining': player.duration() - player.currentTime()
  })

  const stopLiveInterval = () => clearInterval(timer)

  const startLiveInterval = () => {
    timer = setInterval(() => duration++, 1000)
  }

  const turnCaptionsOnHandler = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('videoCaptions', true)
    }
  }

  const turnCaptionsOffHandler = () => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('videoCaptions')
    }
  }

  const playing = (comscore, metadata) => {
    if (startOfVideo) {
      updateMetadata(comscore, metadata)
      comscore.createPlaybackSession()
      startOfVideo = false
    }

    let videoCaptionsOn = getVideoCaptionPreference()
    const captionButton = document.getElementsByClassName('vjs-menu-item vjs-captions-menu-item')[0]

    captionButton?.addEventListener('click', turnCaptionsOnHandler)
    captionButton?.previousElementSibling?.addEventListener('click', turnCaptionsOffHandler)

    if (videoCaptionsOn) {
      captionButton?.click()
    }

    if (isLive) {
      startLiveInterval()
    }

    if (!trackedPlay) {
      quartiles = [0.25, 0.5, 0.75, 0.9]
      track('Start', videoAttrs())
      trackedPlay = true
    } else {
      track('Resume', videoAttrs(videoRemains()))
    }
    comscore.notifyPlay()
  }

  const pause = comscore => {
    if (isLive) {
      stopLiveInterval()
    }
    track('Pause', videoAttrs(Object.assign(videoAttrs({ value: player.currentTime() }), videoRemains())))
    comscore.notifyPause()
  }

  const ended = comscore => {
    track('Complete', videoAttrs({ value: player.duration() }))
    comscore.notifyEnd()
    startOfVideo = true
    setNextPlaybackMetadata()
  }

  const setNextPlaybackMetadata = () => {
    let sa = new analytics.StreamingAnalytics()
    let cm = new analytics.StreamingAnalytics.ContentMetadata()

    cm.setUniqueId(src)
    cm.setProgramTitle(isLive ? `${region.shortName}-live` : overlayText)
    updateMetadata(sa, cm)
    player.on('playing', () => playing(sa, cm))
    player.on('pause', () => pause(sa))
    player.on('ended', () => ended(sa))
  }

  const timeupdate = () => {
    if (!isLive) {
      const pctPlayed = player.currentTime() / player.duration()
      if (quartiles.length > 0 && pctPlayed > quartiles[0]) {
        const quartile = quartiles.shift()
        track(`Progress_${parseInt(quartile * 100, 10)}`, videoAttrs({ value: player.currentTime() }))
      }
    } else {
      const minutesWatched = Math.floor(duration / 60)
      if (durationMap.has(minutesWatched) && durationMap.get(minutesWatched)) {
        track(`Duration_${minutesWatched}`, videoAttrs({ hour: timezone().tz('America/New_York').hour() }))
        durationMap.set(minutesWatched, false)
      }
    }
  }

  const createPlayer = () => {
    const overrideNative =
      !videojs.browser.IS_SAFARI && !(navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i))

    window.videojs = videojs

    return window.videojs(
      videoNode.current,
      {
        muted,
        textTrackSettings: false,
        html5: {
          nativeTextTracks: !overrideNative,
          hls: { overrideNative },
          nativeAudioTracks: !overrideNative,
          nativeVideoTracks: !overrideNative
        }
      },
      () => delayControlHide()
    )
  }

  const setUpPlayer = withAds => {
    const player = createPlayer()
    withAds && setupIMA(player)
    setupOverlay(player)
    setInitialized(true)

    if (isLive) {
      window.onunload = () => trackMinutesWatched()
    }

    return player
  }

  const setupIMA = player => {
    if (window.google?.ima) {
      try {
        player.ima({
          adTagUrl: adTag(region, isLive, date, title, tags, slug, contentfulCategories)
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const setupOverlay = player => {
    if (overlayText) {
      const overlayContent = `<div>${overlayText}</div>`
      player.overlay({
        overlays: [
          {
            start: 'loadstart',
            content: overlayContent,
            end: 'playing'
          },
          {
            start: 'pause',
            content: overlayContent,
            end: 'playing'
          }
        ]
      })
    }
  }

  const delayControlHide = () => {
    timeOut.current = setTimeout(() => {
      if (player && !cleaningUp) {
        setShowControls(false)
      }
    }, 2200)
  }

  const showControlsFunc = () => {
    if (timeOut.current) {
      clearTimeout(timeOut.current)
    }
    setShowControls(true)
    delayControlHide()
  }

  const getClassName = () => {
    const is_live = isLive ? ' is_live' : ''
    const show_controls = showControls ? ' show_controls' : ''

    return `${className} video_player${is_live}${show_controls}`
  }

  return (
    <>
      <Head>
        <Script
          type='text/javascript'
          src='https://sb.scorecardresearch.com/c2/plugins/streaming-videojs/v1/current.js'
        />
      </Head>
      <div data-testid='video_player' className={getClassName()} onMouseMove={showControlsFunc}>
        <div data-vjs-player id='content_video' className='video-js'>
          <video ref={videoNode} muted={muted} className='video-js' poster={poster} playsInline autoPlay={autoplay} />
        </div>
      </div>
      <style global jsx>
        {video}
      </style>
    </>
  )
}

export default VideoPlayer
