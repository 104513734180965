import css from 'styled-jsx/css'

const iconCheese = '\\e91a'
const iconPlay = '\\e900'
const iconPause = '\\e901'
const iconVolume = '\\e902'
const iconMute = '\\e903'
const iconFullScreen = '\\e904'
const iconMinimize = '\\e905'
const iconCC = '\\e908'

export const video = css.global`
  @font-face {
    font-family: 'icons';
    src: url('/fonts/icons.eot?ucu5ne#iefix') format('embedded-opentype'), url('/fonts/icons.woff2?ucu5ne') format('woff2'), url('/icons.ttf?ucu5ne') format('truetype'), url('/icons.woff?ucu5ne') format('woff'), url('/icons.svg?ucu5ne#icons') format('svg'); font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'VideoJS';
    src: url('/fonts/VideoJS.woff') format('woff');
  }

  .error {
    display: none;
  }

  .video_player {
    font-weight: 300;
    font-size: 14px;
    color: white;
    position: relative;
    overflow: hidden;
  }

  .video-js.vjs-paused > .vjs-control-bar {
    opacity: 1;
  }

  .video-js {
    width: 100%;
    height: 0;
    padding-top: 0;
    background: black;
    padding-top: 56.25%
  }

  video.video-js, video.vjs-tech {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .vjs-hidden,
  .vjs-modal-dialog-content,
  .vjs-playback-rate,
  .vjs-chapters-button,
  .vjs-descriptions-button,
  .vjs-duration,
  .vjs-big-play-button,
  .vjs-audio-button,
  .vjs-resize-manager {
    display: none;
  }

  .vjs-picture-in-picture-control {
    display: none;
  }

  .vjs-seek-to-live-control {
    display: none;
  }

  .my-class {
    width: 659px;
    height: 407px;
    padding: 32px;
  }

  .video-js {
    height: 100%;
    width: 100%;
  }

  .vjs-overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    font-size: 24px;
    background: rgba(0,0,0,0.5);
    font-weight: normal;
    margin: auto;
  }

  .vjs-overlay > div {
    position: absolute;
    bottom: 0;
    margin-bottom: 73px;
    width: 95%;
    margin-left: 14px;
  }

  .vjs-control {
    outline: 0;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .vjs-control > .vjs-live-control {
    z-index: 30;
  }

  .vjs-custom-control-spacer {
    flex-grow: 1000;
  }

  .vjs-button {
    font-family: "icons"!important;
    font-size: 1.111rem;
    z-index: 30;
    padding: 0 7px;
    background: 0 0;
    border: none;
    cursor: pointer;
    color: white;
  }

  .vjs-button ::before {
    display: inline;
    width: 100%;
    font-family: 'icons' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    color: #fff;
    text-align: center;
  }

  .vjs-control-text {
    display: none;
  }

  .vjs-play-control {
    margin-left: 7px;
    width: 2rem;
  }

  .vjs-play-control::before {
    content: '${iconPlay}';
  }

  .vjs-play-control.vjs-playing::before {
    content: '${iconPause}';
  }

  .vjs-volume-panel {
    z-index: 30;
  }

  .vjs-volume-panel > .vjs-mute-control::before {
    content: '${iconVolume}';
  }

  .vjs-volume-panel > .vjs-mute-control.vjs-vol-0::before {
    content: '${iconMute}';
  }

  .vjs-mute-control.vjs-vol-0::before {
    content: '${iconMute}';
  }

  .vjs-fullscreen-control {
    margin-right: 7px;
  }

  .vjs-fullscreen-control::before {
    content: '${iconFullScreen}';
  }

  .vjs-control-bar {
    opacity: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 65px;
    left: 0;
    transition: opacity 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .vjs-control-bar::before {
    background-repeat: repeat-x;
    background-position: bottom;
    content: ' ';
    position: absolute;
    top: -55px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .vjs-control-bar > * {
    margin-top: 6px;
  }

  .vjs-progress-control {
    padding: 0;
    z-index: 60;
    position: absolute;
    left: 12px;
    right: 12px;
    height: 24px;
    margin-top: 0;
  }

  .vjs-progress-control > .vjs-control-text {
    display: none;
  }

  .vjs-progress-control:hover > .vjs-progress-holder {
    height: 7px;
    transform: translate(0, -2px);
  }

  .vjs-progress-control:hover  .vjs-play-progress::after {
    transition: transform 0.2s, opacity 0.2s;
    opacity: 1;
    transform: scale(1.75, 1.75) translate(0, 1px);
  }

  .vjs-progress-control:hover  .vjs-mouse-display {
    opacity: 1;
  }

  .vjs-progress-control:hover  .vjs-time-tooltip {
    transform: translate(0, -3px);
  }

  .vjs-progress-holder {
    background: rgba(128, 128, 128, 0.75);
    position: absolute;
    left: 0;
    top: 10px;
    bottom: 0;
    right: 0;
    height: 3px;
    cursor: pointer;
    transition: height 0.2s, transform 0.2s;
  }

  .vjs-mouse-display {
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 2px;
    height: 7px;
    transition: opacity 0.2s;
    z-index: 10;
  }

  .vjs-load-progress, .vjs-play-progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }

  .vjs-load-progress {
    background: rgba(256, 256, 256, 0.75);
  }

  .vjs-play-progress {
    background: #FFFF63;
  }

  .vjs-play-progress > .vjs-time-tooltip {
    display: none;
  }

  .vjs-play-progress::after {
    display: block;
    position: absolute;
    background: #FFFF63;
    content: ' ';
    width: 9px;
    height: 9px;
    border-radius: 4.5px;
    z-index: 20;
    top: -3px;
    right: -7px;
    opacity: 0;
    transition: transform 0.2s;
  }

  .vjs-time-control {
    z-index: 30;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 14px;
    font-weight: 500;
  }

  .vjs-time-divider {
    margin: 12px 2px 0 3px;
  }

  .vjs-current-time {
    margin-left: 7px;
  }

  .vjs-remaining-time {
    display: none;
  }

  .vjs-time-tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    top: -27px;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 3px;
    transition: transform 0.2s;
  }

  .vjs-live-display {
    margin-top: 17px;
    padding: 8px 28px 0 14px;
    border-radius: 20px;
    line-height: 14px;
  }

  .vjs-live-display.vjs-control-text {
    display: none;
  }

  .vjs-live-display::after {
    display: block;
    content: ' ';
    position: relative;
    width: 7px;
    height: 7px;
    background: white;
    border-radius: 3.5px;
    bottom: 11px;
    left: 36px;
  }

  .video-js.vjs-fullscreen > .vjs-fullscreen-control::before {
    content: icon-exit-fullscreen;
  }

  .vjs-loading-spinner .vjs-control-text {
    display: none;
  }

  .vjs-seeking.vjs-loading-spinner:before,
  .vjs-waiting.vjs-loading-spinner:before,
  .vjs-loadstart.vjs-loading-spinner:before,
  .vjs-scrubbing.vjs-loading-spinner:before,
  .init-pause-state.vjs-loading-spinner:before {
    content: ${iconCheese};
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    opacity: 1;
    text-align: center;
    height: 5rem;
    width: 5rem;
    line-height: 5rem;
    background-clip: padding-box;
    font-family: icons;
    font-size: 5rem;
    animation: spin .8s infinite linear;
  }

  .vjs-subs-caps-button {
    display: flex;
    align-items: center;
  }

  .vjs-subs-caps-button button {
    display: none;
  }

  .vjs-subs-caps-button .vjs-menu.vjs-hidden {
    display: block;
  }

  .vjs-subs-caps-button .vjs-menu .vjs-menu-content {
    list-style-type: none;
  }

  .vjs-subs-caps-button .vjs-menu .vjs-menu-item {
    display: block;
    font-family: 'icons' !important;
    outline: none;
  }

  .vjs-subs-caps-button .vjs-menu .vjs-menu-item::before,
  .vjs-subs-caps-button .vjs-menu .vjs-captions-menu-item::before {
    content: '${iconCC}';
    font-size: 1.111rem;
    color: white;
    border-width: 2px;
    border-style: solid;
    border-radius: 6px;
    border-color: #ffff63;
  }
  
  .vjs-menu .vjs-menu-item.vjs-captions-menu-item:nth-of-type(3) {
    display: none;
  }

  .vjs-subs-caps-button .vjs-menu .vjs-captions-menu-item::before {
    border-color: white;
  }

  .vjs-subs-caps-button .vjs-menu .vjs-selected {
    display: none;
  }

  .vjs-subs-caps-button .vjs-menu .vjs-menu-item-text,
  .vjs-subs-caps-button .vjs-menu .vjs-control-text,
  .vjs-subs-caps-button .vjs-menu .vjs-texttrack-setting {
    display: none;
  }

  .vjs-text-track-display .vjs-text-track-cue {
    width: 100% !important;
    height: auto !important;
    top: 10px !important;
    font-size: 1.4em !important;
    font-family: inherit !important;
    font-weight: 500 !important;
    opacity: 0.8;
  }

  .vjs-text-track-display > div > div > div {
    display: inline-block !important;
    padding: 20px 40px;
  }

  .show_controls > .video-js > .vjs-control-bar {
    opacity: 1;
  }

  .video-js .vjs-slider {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 0 0.45em 0 0.45em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #73859f;
    background-color: rgba(115, 133, 159, 0.5);
  }

  .video-js .vjs-slider.disabled {
    cursor: default;
  }

  .video-js .vjs-slider:focus {
    text-shadow: 0em 0em 1em white;
    box-shadow: 0 0 1em #fff;
  }

  .video-js .vjs-mute-control {
    cursor: pointer;
    flex: none;
    margin-right: 5px;
  }

  .video-js .vjs-volume-control {
    cursor: pointer;
    display: flex;
  }

  .video-js .vjs-volume-control.vjs-volume-horizontal {
    width: 5em;
    padding-top: 12px;
    margin-right: 5px;
  }

  .video-js .vjs-volume-panel .vjs-volume-control {
    visibility: visible;
    opacity: 0;
    width: 1px;
    height: 1px;
    margin-left: -5px;
  }

  .video-js .vjs-volume-panel {
    transition: width 1s;
  }

  .video-js .vjs-volume-panel.vjs-hover .vjs-volume-control, .video-js .vjs-volume-panel:active .vjs-volume-control, .video-js .vjs-volume-panel:focus .vjs-volume-control, .video-js .vjs-volume-panel .vjs-volume-control:active, .video-js .vjs-volume-panel.vjs-hover .vjs-mute-control ~ .vjs-volume-control, .video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active {
    visibility: visible;
    opacity: 1;
    position: relative;
    transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
  }

  .video-js .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-horizontal, .video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-horizontal, .video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-horizontal, .video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-horizontal, .video-js .vjs-volume-panel.vjs-hover .vjs-mute-control ~ .vjs-volume-control.vjs-volume-horizontal, .video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-horizontal {
    width: 5em;
    height: 3em;
    margin-right: 0;
  }

  .video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover, .video-js .vjs-volume-panel.vjs-volume-panel-horizontal:active, .video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-slider-active {
    width: 8.7em;
    transition: width 0.1s;
  }

  .video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-mute-toggle-only {
    width: 4em;
  }

  .video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
    transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
  }

  .video-js.vjs-no-flex .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
    width: 5em;
    height: 3em;
    visibility: visible;
    opacity: 1;
    position: relative;
    transition: none;
  }

  .video-js .vjs-volume-panel {
    display: flex;
  }

  .video-js .vjs-volume-bar {
    margin: 1.35em 0em;
  }

  .vjs-volume-bar.vjs-slider-horizontal {
    width: 5em;
    height: 0.3em;
  }

  .video-js .vjs-volume-level {
    position: absolute;
    height: 24px;
    margin-top: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
  }

  .video-js .vjs-volume-level:before {
    position: absolute;
    font-size: 0.9em;
  }
  
  .vjs-slider-horizontal .vjs-volume-level {
    height: 0.3em;
  }
  
  .vjs-slider-horizontal .vjs-volume-level:before {
    top: -0.3em;
    right: -0.5em;
  }

  .vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level {
    width: 100%;
  }

  .video-js .vjs-volume-horizontal .vjs-menu {
    left: -2em;
  }
  
  .video-js .vjs-volume-level {
    font-family: VideoJS;
    font-weight: normal;
    font-style: normal;
  }
  
  .video-js .vjs-volume-level:before {
    content: ${`"\\f111"`};
  }
`
