const liveDurationMap = new Map()
liveDurationMap.set(1, true) //key represents minute duration
liveDurationMap.set(2, true)
liveDurationMap.set(3, true)
liveDurationMap.set(4, true)
liveDurationMap.set(5, true)
liveDurationMap.set(10, true)
liveDurationMap.set(15, true)
liveDurationMap.set(20, true)
liveDurationMap.set(25, true)
liveDurationMap.set(30, true)

export default liveDurationMap
